import styled from 'styled-components';

export const Input = styled.input`
  width: 215px;
  padding: 14px;
  background-color: #ffffff;
  border: 2px solid #ffffff;

  border-radius: 2px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  color: #000000;
  border-radius: 6px;

  &:hover {
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
  }

  &focus {
    outline: none;
    box-shadow: none;
    border: transparent;
  }

  &:invalid {
    border-color: red;
    box-shadow: 0 0 10px red;
  }
`;
