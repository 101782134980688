import React from 'react';
import styled from 'styled-components';

const colors = {
  primary: { color: '#1d307b', border: 'none' },
  secondary: { color: 'transparent', border: '3px solid #1B2B57' },
  gradient: { color: 'linear-gradient(90deg, #4ba2d9 0%, #4163ba 98.44%)', border: 'none' },
};
type Color = keyof typeof colors;

type StyledButtonProps = { theme: { color: string; border: string } };
const StyledButton = styled.button<StyledButtonProps>`
  background: ${(props) => props.theme.color};
  border: ${(props) => props.theme.border};
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  padding: 18px 31px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: all 0.5s;

  &:hover {
    filter: hue-rotate(90deg);
    transform: perspective(1500px) rotateY(15deg);
  }
`;

type Props = {
  children: React.ReactNode;
  color?: Color;
  onClick?: () => void;
};

export const Button: React.FC<Props> = ({ children, color = 'primary', onClick }) => {
  return (
    <StyledButton theme={colors[color]} onClick={() => onClick?.call(this)}>
      {children}
    </StyledButton>
  );
};
