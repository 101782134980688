import React from 'react';
import styled from 'styled-components';
import { Icon, Names } from '../Icons/Icon';

export const StyledFeatureCard = styled.div`
  display: grid;
  width: 200px;
  padding: 25px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  border: solid 2px #1b2b57;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 620px) {
    width: 370px;
  }

  @media (max-width: 520px) {
    width: 270px;
  }

  @media (max-width: 420px) {
    width: 170px;
  }
`;

const Title = styled.h4`
  margin: 0;
`;

const SubTitle = styled.span`
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding-bottom: 10px;
`;

const Button = styled.button`
  width: 100%;
  background-color: #1d307b;
  border-style: none;
  border-radius: 6px;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: all 0.5s;
  outline: inherit;

  &:hover {
    filter: hue-rotate(20deg);
  }
`;

type Props = {
  title: string;
  subtitle: string;
  icon: Names;
};

export const FeatureCard: React.FC<Props> = ({ title, subtitle, icon }) => {
  return (
    <StyledFeatureCard>
      <Icon name={icon} />
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <Button>DEMONSTRAÇÃO</Button>
    </StyledFeatureCard>
  );
};
