import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../components/Card';
import { Spinner } from '../components/Spinner/Spinner';
import { recoveryPassword } from '../scripts/api';

import styled from 'styled-components';
import { NavBar } from '../components/NavBar';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  row-gap: 12px;
`;

type Feedback = { status: 'SUCESS' | 'ERROR' | undefined; message: string };

const FeedbackTab = styled.div<{ sucess?: boolean }>`
  background-color: ${(props) => (props.sucess ? '#006132' : '#A10101')};
  padding: 10px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
`;

export const EmailConfirmationPage: React.FC = () => {
  const [feedback, setFeedback] = useState<Feedback>();

  const { email } = useParams();

  useEffect(() => {
    if (email) {
      recoveryPassword(email)
        .then((res) => {
          if (res.status === 200) {
            setFeedback({ status: 'SUCESS', message: 'Enviamos um link de recuperação para o seu email !' });
          } else {
            setFeedback({ status: 'ERROR', message: 'Não foi possivel atender a sua solicitação.' });
          }
        })
        .catch(() => {
          setFeedback({
            status: 'ERROR',
            message: 'Este link ja foi utilizado para recuperar sua senha, verifique sua caixa de spam.',
          });
        });
    } else {
      setFeedback({
        status: 'ERROR',
        message: 'Este link ja foi utilizado para recuperar sua senha, verifique sua caixa de spam.',
      });
    }
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <Card>
          <Content>
            {feedback?.status === undefined && <Spinner />}
            {feedback?.status === 'SUCESS' && <FeedbackTab sucess>{feedback.message}</FeedbackTab>}
            {feedback?.status === 'ERROR' && <FeedbackTab>{feedback.message}</FeedbackTab>}
          </Content>
        </Card>
      </Container>
    </>
  );
};
