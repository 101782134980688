import styled from 'styled-components';

export const Card = styled.div`
  width: 245px;
  padding: 28px;
  background-color: #0b1950;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
`;
