import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { Input } from '../components/Input';
import { updatePassword } from '../scripts/api';

import styled from 'styled-components';
import { NavBar } from '../components/NavBar';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  margin-top: 20px;
  display: grid;
  justify-content: center;
  row-gap: 12px;
`;

const Label = styled.label`
  font-size: 12px;
`;

type Feedback = { status: 'SUCESS' | 'ERROR' | undefined; message: string };

const FeedbackTab = styled.div<{ sucess?: boolean }>`
  background-color: ${(props) => (props.sucess ? '#006132' : '#A10101')};
  padding: 10px;
  font-weight: 600;
  font-size: 9px;
  text-align: center;
`;

export const RecoveryPasswordPage: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [feedback, setFeedback] = useState<Feedback>();

  const { token } = useParams();

  const isValid = (pass: string) => {
    if (pass.length >= 6) return true;
    return false;
  };

  const requestPass = () => {
    if (token && isValid(password)) {
      updatePassword(token, password)
        .then((res) => {
          if (res.status === 200) {
            setFeedback({ status: 'SUCESS', message: 'Senha alterada com sucesso!' });
          } else {
            setFeedback({ status: 'ERROR', message: 'Link expirado!' });
          }
        })
        .catch(() => {
          setFeedback({ status: 'ERROR', message: 'Link expirado!' });
        });
    } else {
      setFeedback({ status: 'ERROR', message: 'A senha deve ter pelo menos 6 caracteres' });
    }
  };

  useEffect(() => {
    if (password !== confirmPassword) {
      setFeedback({ status: 'ERROR', message: 'As senhas digitadas são diferentes.' });
    } else {
      setFeedback({ status: undefined, message: '' });
    }
  }, [confirmPassword]);

  if (feedback?.status !== 'SUCESS')
    return (
      <>
        <NavBar />
        <Container>
          <Card>
            <h3>Recuperar Senha</h3>
            <Content>
              <Label htmlFor="password">Nova senha</Label>
              <Input id="password" value={password} type="password" onChange={(evt) => setPassword(evt.target.value)} />
              <Label htmlFor="password">Confirmar senha</Label>

              <Input
                id="confirmPassword"
                value={confirmPassword}
                type="password"
                onChange={(evt) => setConfirmPassword(evt.target.value)}
              />
              {feedback?.status === 'ERROR' && <FeedbackTab>{feedback.message}</FeedbackTab>}
              <Button onClick={() => requestPass()}>ALTERAR SENHA</Button>
            </Content>
          </Card>
        </Container>
      </>
    );

  return (
    <>
      <NavBar />
      <Container>
        <Card>
          <h3>Recuperar Senha</h3>
          <Content>
            <FeedbackTab sucess>{feedback.message}</FeedbackTab>
          </Content>
        </Card>
      </Container>
    </>
  );
};
