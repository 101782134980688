import { RefObject, useEffect } from 'react';

/**
 * Triggers an effect if a html object is clicked, except if it's the one
 * passed as reference.
 *
 * @param ref Reference to object that doesn't count to be clicked on to
 * trigger this effect.
 * @param callback Callback to be called once this effect is triggered.
 */

export function useOutsideClick<T extends HTMLElement>(ref: RefObject<T> | RefObject<T>[], callback: () => void): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const result = Array.isArray(ref)
        ? ref.every((ref) => {
            /* Added extension to ref array */
            return ref.current && event.target instanceof Node && !ref.current.contains(event.target);
          })
        : ref.current && event.target instanceof Node && !ref.current.contains(event.target);

      if (result) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, ref]);
}
