import axios from 'axios';

const API_BASE_URL = 'https://api.rog3r.app';

export const requestPassword = async (email: string) => {
  return await axios
    .post(`${API_BASE_URL}/request/password`, {
      email,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const recoveryPassword = async (email: string) => {
  return await axios
    .post(`${API_BASE_URL}/recovery/password`, {
      email,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const updatePassword = async (token: string, password: string) => {
  return await axios({
    method: 'put',
    url: `${API_BASE_URL}/update/password`,
    headers: { token: token },
    data: { password: password },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
