import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { Input } from '../components/Input';
import { NavBar } from '../components/NavBar';
import { requestPassword } from '../scripts/api';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  margin-top: 20px;
  display: grid;
  justify-content: center;
  row-gap: 12px;
`;

const Label = styled.label`
  font-size: 12px;
`;

type Feedback = { status: 'SUCESS' | 'ERROR' | undefined; message: string };

const FeedbackTab = styled.div<{ sucess?: boolean }>`
  background-color: ${(props) => (props.sucess ? '#006132' : '#A10101')};
  padding: 10px;
  font-weight: 600;
  font-size: 9px;
  text-align: center;
`;

export const RequestPasswordPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState<Feedback>();

  const isValid = (mail: string) => {
    const re = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;
    return re.test(mail);
  };

  const requestPass = () => {
    if (isValid(email)) {
      requestPassword(email)
        .then((res) => {
          if (res.status === 200) {
            setFeedback({ status: 'SUCESS', message: 'Enviamos um email para você, Verifique sua caixa de entrada !' });
          } else {
            setFeedback({ status: 'ERROR', message: 'Não foi possivel atender a sua solicitação.' });
          }
        })
        .catch(() => {
          setFeedback({ status: 'ERROR', message: 'Não foi possivel atender a sua solicitação.' });
        });
    } else {
      setFeedback({ status: 'ERROR', message: 'E-mail inválido.' });
    }
  };

  if (feedback?.status !== 'SUCESS')
    return (
      <>
        <NavBar />
        <Container>
          <Card>
            <h3>Recuperar Senha</h3>
            <Content>
              <Label htmlFor="email">Digite seu email</Label>
              <Input id="email" value={email} type="email" onChange={(evt) => setEmail(evt.target.value)} />
              {feedback?.status === 'ERROR' && <FeedbackTab>{feedback.message}</FeedbackTab>}
              <Button onClick={() => requestPass()}>RECUPERAR SENHA</Button>
            </Content>
          </Card>
        </Container>
      </>
    );

  return (
    <>
      <NavBar />
      <Container>
        <Card>
          <h3>Recuperar Senha</h3>
          <Content>
            <FeedbackTab sucess>{feedback.message}</FeedbackTab>
          </Content>
        </Card>
      </Container>
    </>
  );
};
