import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/outsideClick';
import { Icon } from '../Icons';
import { Names } from '../Icons/Icon';
import logo from '../../resources/logo.png';

const Container = styled.div`
  grid-area: SideBar;
  justify-self: end;
  padding-top: 25px;
`;

const SidebarShade = styled.div<{ hidden: boolean }>`
  z-index: 6;

  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  background-color: black;
  opacity: ${(props) => (props.hidden ? 0 : 0.34)};
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  transition: linear 0.25s;
`;

const StyledSideBar = styled.div<{ hidden: boolean }>`
  display: grid;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  position: fixed;

  grid-auto-rows: max-content;
  row-gap: 20px;
  top: 0;
  width: 257px;
  height: 100%;
  right: ${(props) => (props.hidden ? '-257px' : 0)};
  z-index: 7;
  padding-top: 3vh;
  background-color: #03103e;
  justify-content: center;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  transition: linear 0.25s;
`;

const Item = styled.span`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 18px;
  width: 232px;
  height: 45px;
  align-items: center;

  &&:hover {
    background-color: #1d307b;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const Text = styled.span`
  font-family: Inter;
  font-style: Regular;
  font-size: 16px;
  color: white;
`;

const IconContent = styled.span`
  padding-left: 30px;
`;

const LogoContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

type Props = {
  onHandle?(state: boolean): void;
  options: {
    icon: Names;
    name: string;
    onClick: () => void;
  }[];
};

export const SideBar: React.FC<Props> = ({ options, onHandle }) => {
  const [hidden, setHidden] = useState(true);

  const wrapperRef = useRef(null);
  useOutsideClick(
    wrapperRef,
    useCallback(() => setHidden(true), [setHidden])
  );

  useEffect(() => {
    onHandle && onHandle(hidden);
  }, [hidden]);

  return (
    <Container>
      <Icon name="Hamburguer" size="large" onClick={() => setHidden(false)} color="light" />
      <SidebarShade hidden={hidden} />
      <StyledSideBar hidden={hidden} ref={wrapperRef}>
        <LogoContent>
          <img width="155px" src={logo} alt="ROG3R" />
        </LogoContent>
        {options.map((value, index) => {
          return (
            <Item
              onClick={() => {
                value.onClick();
                setHidden(true);
              }}
              key={index}
            >
              <IconContent>
                <Icon name={value.icon} size="small" />
              </IconContent>
              <Text>{value.name}</Text>
            </Item>
          );
        })}
      </StyledSideBar>
    </Container>
  );
};
