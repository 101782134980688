import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../resources/logo.png';
import background from '../resources/background.png';
import ufoImg from '../resources/ufo.png';
import iphoneImg from '../resources/iphone.png';
import devices from '../resources/devices.png';
import devices2 from '../resources/devices_2.png';
import innaveFooter from '../resources/innave-footer.png';
import rog3rFooter from '../resources/rog3r-footer.png';
import faq from '../resources/faq.png';

import { Button } from '../components/Button';
import { FeatureCard } from '../components/FeatureCard';
import { useMedia } from '../hooks/mediaQuery';
import { SideBar } from '../components/SideBar';
import { Modal } from '../components/Modal';
import { Input } from '../components/Input';

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const Content = styled.div`
  display: grid;
  justify-content: center;
  gap: 40px;
  padding: 50px;

  @media (max-width: 470px) {
    padding: 30px;
  }

  grid-template:
    'Logo     SideBar' max-content
    'Content  Animation' min-content /
    max-content max-content;

  @media (max-width: 1100px) {
    grid-template:
      'Logo     SideBar' max-content
      'Content  Animation' min-content /
      1fr 1fr;
  }

  @media (max-width: 860px) {
    grid-template:
      'Logo SideBar' max-content
      'Animation Animation' min-content
      'Content Content' min-content /
      1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template:
      '. Logo SideBar' max-content
      'Animation Animation Animation' min-content
      'Content Content Content' min-content /
      1fr 1fr 1fr;
    gap: 30px 5px;
  }

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${background});
  border-bottom: 8px solid #1d307b;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${background});
  border-bottom: 8px solid #1d307b;
  justify-content: center;
`;

const TopicContainer = styled.div`
  display: flex;
  width: 720px;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const MoveBackground = keyframes`
  0% {
    right: 0;
	}
  50% { 
    right: 500px;
	} 
  100% { 
    right: 0;
	} 
`;

const UfoAnimation = keyframes`
  0% {
    top: 0;
	}
  50% { 
    top: 30px;
	} 
  100% { 
    top: 0;
	} 
`;

const IphoneAnimation = keyframes`
  0% {
    bottom: 0;
	}
  50% { 
    bottom: 30px;
	} 
  100% { 
    bottom: 0;
	} 
`;

const AnimationContainer = styled.div`
  grid-area: Animation;
  position: relative;
  width: 410px;
  height: 570px;
  justify-self: center;

  @media (max-width: 1100px) {
    width: 255px;
    height: 450px;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }

  @media (max-width: 950px) {
    height: 380px;
  }

  @media (max-width: 364px) {
    width: 100%;
  }
`;

const Stars = styled.div`
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: -3;
`;

const Twinkling = styled.div`
  width: 100%;
  height: 100%;
  background: transparent url('https://i.imgur.com/dXl7Sku.png') repeat;
  background-size: 1000px 1000px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -2;
  animation: ${MoveBackground} 40s linear infinite;
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 70px;
  grid-area: SideBar;
  align-items: center;

  span {
    transition: all 0.3s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  a {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: white;

    &:hover {
      background: linear-gradient(45deg, #4ba2d9, #00ff95 80%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const UfoContent = styled.div`
  grid-area: Content;
  width: 370px;

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const LogoImg = styled.img<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  width: 181px;
  grid-area: Logo;
  transform: perspective(1500px) rotateY(15deg);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: transform 1s ease 0s;

  @media (max-width: 470px) {
    width: 155px;
  }

  &:hover {
    transform: perspective(3000px) rotateY(5deg) scale(1.1);
  }
`;

const Ufo = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  animation: ${UfoAnimation} 5s linear infinite;
`;

const Iphone = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  animation: ${IphoneAnimation} 5s linear infinite;
`;

const Section = styled.div<{ dark?: boolean }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.dark ? '#030E36' : '#03103E')};
  padding: 40px;
`;

const FeatureContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 20px;
  justify-content: center;

  @media (max-width: 1120px) {
    grid-template-columns: repeat(2, max-content);
  }

  @media (max-width: 620px) {
    grid-template-columns: max-content;
  }
`;

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  justify-content: center;
  justify-items: center;
  align-items: center;

  @media (max-width: 1020px) {
    grid-template-columns: 1fr;
  }
`;

const Footer = styled.div`
  display: grid;
  gap: 10px;
  padding: 70px;
  font-size: 12px;

  text-align: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
`;

const Title = styled.h1`
  @media (max-width: 570px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  /* @media (max-width: 570px) {
    font-size: 10px;
    line-height: 14px;
  } */
`;

const Description = styled.text`
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
`;

const openWhatsapp = () => {
  window.open('whatsapp://send?phone=5581993233005', '_self');
};

const openLink = (link: string) => {
  window.open(link, '_blank');
};

export const HomePage: React.FC = () => {
  const [logoVisibility, setLogoVisibility] = useState(true);
  const [modal, setModal] = useState(false);
  const [codeModal, setCodeModal] = useState(false);
  const [promotionCode, setPromotionCode] = useState('');
  const [isValid, setIsValid] = useState(false);

  const featuresSection = useRef<HTMLDivElement>(null);
  const demoSection = useRef<HTMLDivElement>(null);
  const faqSection = useRef<HTMLDivElement>(null);
  const priceSection = useRef<HTMLDivElement>(null);

  const isTablet = useMedia('(max-width: 1100px)');
  const isMobile = useMedia('(max-width: 515px)');
  const isMedium = useMedia('(max-width: 600px)');
  const isSmall = useMedia('(max-width: 364px)');

  const sections = {
    features: featuresSection,
    demo: demoSection,
    faq: faqSection,
    price: priceSection,
  };
  type Section = keyof typeof sections;

  const scrollTo = (section: Section) => {
    sections[section]?.current?.scrollIntoView();
  };

  useEffect(() => {
    console.log(promotionCode);
    if (promotionCode.toUpperCase() === 'EXPOISP2022') {
      setIsValid(true);
      setCodeModal(false);
    } else {
      setIsValid(false);
    }
  }, [promotionCode]);

  return (
    <Container>
      <Stars />
      <Twinkling />
      <Content>
        <LogoImg src={logo} alt="ROG3R" visible={logoVisibility} />
        {isTablet ? (
          <SideBar
            onHandle={(state) => setLogoVisibility(state)}
            options={[
              { icon: 'Schedule', name: 'Funcionalidades', onClick: () => scrollTo('features') },
              { icon: 'Download', name: 'App Demo', onClick: () => scrollTo('demo') },
              { icon: 'Info', name: 'F.A.Q', onClick: () => scrollTo('faq') },
              { icon: 'Dollar', name: 'Preços', onClick: () => scrollTo('price') },
            ]}
          />
        ) : (
          <Menu>
            <span>
              <a onClick={() => scrollTo('features')}>Funcionalidades</a>
            </span>
            <span>
              <a onClick={() => scrollTo('demo')}>App de Demonstração</a>
            </span>
            <span>
              <a onClick={() => scrollTo('faq')}>F.A.Q</a>
            </span>
            <span>
              <a onClick={() => scrollTo('price')}>Preços</a>
            </span>
          </Menu>
        )}

        <UfoContent>
          <h1>Permita que o seu Provedor seja abduzido pela nossa Nave !</h1>
          <Description>
            Desenvolvemos o aplicativo do seu provedor para Android e Ios integrado ao seu sistema atual de
            gerenciamento. Sem burócracia, sem taxas extras, com uma mensalidade fixa que cabe no seu bolso.
          </Description>
          <span
            style={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr 1fr' : 'max-content max-content',
              marginTop: '30px',
              marginBottom: '70px',
            }}
          >
            <Button color="gradient">OBTER MEU APP</Button>
            <Button color="secondary" onClick={() => openWhatsapp()}>
              ENTRAR EM CONTATO
            </Button>
          </span>
        </UfoContent>
        <AnimationContainer>
          <Ufo>
            <img width={isTablet ? '255px' : isSmall ? '100%' : '410px'} src={ufoImg} alt="ROG3R" />
          </Ufo>
          <Iphone>
            <img width={isTablet ? '255px' : isSmall ? '100%' : '410px'} src={iphoneImg} alt="ROG3R" />
          </Iphone>
        </AnimationContainer>
      </Content>
      <Header />

      <Section ref={featuresSection}>
        <TopicContainer>
          <Title>Funcionalidades e Recursos</Title>
          <Description>
            Desenvolvemos o aplicativo do seu provedor para Android e Ios integrado ao seu sistema atual de
            gerenciamento. Sem burócracia, sem taxas extras, com uma mensalidade fixa que cabe no seu bolso.
          </Description>
          <div>
            <Subtitle>Funcionalidades da nossa plataforma</Subtitle>
            <FeatureContainer>
              <FeatureCard
                icon="Ethernet"
                title="Planos"
                subtitle="Seus clientes conseguem consultar e assinar planos oferecidos pelo seu provedor"
              />
              <FeatureCard
                icon="Company"
                title="Sobre sua Empresa"
                subtitle="Seus clientes conseguem acessar informações sobre sua empresa. Missão, Visão e Valores"
              />
              <FeatureCard
                icon="Bell"
                title="Notificações"
                subtitle="Seus clientes recebem notificações e avisos da central do cliente ou suporte técnico. Além de feedbacks automáticos sobre débitos e faturas."
              />
              <FeatureCard
                icon="Location"
                title="Google Maps"
                subtitle="Seus clientes conseguem consultar a aréa de cobertura ou localização do seu escritório fisico."
              />
            </FeatureContainer>
          </div>

          <div>
            <Subtitle>Funcionalidades do seu sistema integradas ao App</Subtitle>
            <FeatureContainer>
              <FeatureCard
                icon="BarCode"
                title="Boleto"
                subtitle="Seus clientes conseguem obter o código de barras ou baixar o boleto em PDF, diretamente no App."
              />
              <FeatureCard
                icon="Pix"
                title="Pix"
                subtitle="Seus clientes conseguem obter o código de Pix ou baixar a fatura em PDF, diretamente no App."
              />
              <FeatureCard
                icon="CashHand"
                title="Promessa de Pagamento"
                subtitle="Seus clientes conseguem realizar uma solicitação de desbloqueio por confiança, caso esteja habilitado em seu sistema."
              />
              <FeatureCard
                icon="Support"
                title="Chamados"
                subtitle="Seus clientes conseguem abrir chamados no seu sistema de gerenciamento, diretamente no App."
              />
            </FeatureContainer>
          </div>

          <div>
            <Subtitle>Ferramentas integradas ao seu App</Subtitle>
            <FeatureContainer>
              <FeatureCard
                icon="SpeedTest"
                title="SpeedTest"
                subtitle="Este recurso permite que testes de Download e Upload sejam realizados dentro do App, utilizando os servidores Ookla."
              />
              <FeatureCard
                icon="Fast"
                title="Fast.com"
                subtitle="Este recurso permite que testes de Download e Upload sejam realizados dentro do App, utilizando os servidores da Netflix."
              />
              <FeatureCard
                icon="DownDetector"
                title="DownDetector"
                subtitle="Este recurso fornece aos usuários informações em tempo real sobre o status de vários sites e serviços diretamente no seu App."
              />
              <FeatureCard
                icon="Wireless"
                title="Minha Wifi"
                subtitle="Este recurso se conecta diretamente ao hardware do smartphone, disponibilizando informações sobre a rede Conexão, IP, IP LAN e Subnet"
              />

              <FeatureCard
                icon="WhatsApp"
                title="WhatsApp"
                subtitle="Este recurso permite que seus clientes sejam direcionados ao WhatsApp do seu suporte."
              />
              <FeatureCard
                icon="Telegram"
                title="Telegram"
                subtitle="Este recurso permite que seus clientes sejam direcionados ao Telegram do seu suporte."
              />
              <FeatureCard
                icon="TV"
                title="DisTV Player"
                subtitle="Este recurso permite que seus clientes tenham acesso ao seu serviço de TV. DisTV ou WebPlayer compativel com dispositivos móveis."
              />
              <FeatureCard
                icon="Movie"
                title="Seu OnDemand"
                subtitle="Este recurso permite que seus clientes tenham acesso ao seu serviço de OnDemand. Emby, JellyFin ou WebPlayer compativel."
              />
            </FeatureContainer>
          </div>
        </TopicContainer>
      </Section>

      <Section ref={demoSection} dark>
        <TopicContainer>
          <DoubleContainer>
            <img width={isMedium ? '100%' : '409px'} src={devices} alt="ROG3R" />
            <span style={{ textAlign: 'start' }}>
              <Title>BAIXE O APP DE DEMONSTRAÇÃO</Title>
              <p style={{ color: 'rgba(255, 255, 255, 0.8)', fontWeight: 400, lineHeight: '24px' }}>
                Explore todas as funcionalidades disponiveis para seus clientes na nossa plataforma!
              </p>
              <span
                style={{
                  display: 'grid',
                  gridTemplateColumns: isMobile ? '1fr' : isMedium ? '1fr 1fr' : 'max-content max-content',
                  gap: '10px',
                }}
              >
                <Button
                  color="primary"
                  onClick={() => openLink('https://play.google.com/store/apps/details?id=com.rog3r.seuprovedor')}
                >
                  BAIXAR ANDROID
                </Button>
                <Button
                  color="secondary"
                  onClick={() => openLink('https://apps.apple.com/br/app/seu-provedor-isp/id1602616071')}
                >
                  BAIXAR IOS
                </Button>
              </span>

              <Title>BAIXE O GERENCIADOR</Title>
              <p style={{ color: 'rgba(255, 255, 255, 0.8)', fontWeight: 400, lineHeight: '24px' }}>
                Gerencie o aplicativo de demonstração utilizando o nosso gerenciador. Modifique planos, envie
                notificações... sinta o que é ter um aplicativo de verdade!
              </p>
              <span
                style={{
                  display: 'grid',
                  gridTemplateColumns: isMobile ? '1fr' : isMedium ? '1fr 1fr' : 'max-content max-content',
                  gap: '10px',
                }}
              >
                <Button
                  color="primary"
                  onClick={() => openLink('https://play.google.com/store/apps/details?id=com.rog3r.seuprovedor')}
                >
                  BAIXAR ANDROID
                </Button>
                <Button
                  color="secondary"
                  onClick={() => openLink('https://apps.apple.com/br/app/seu-provedor-isp/id1602616071')}
                >
                  BAIXAR IOS
                </Button>
              </span>
            </span>
          </DoubleContainer>
        </TopicContainer>
      </Section>

      <Section ref={faqSection}>
        <TopicContainer>
          <span style={{ display: 'grid', gap: '20px' }}>
            <h1 style={{ marginBottom: 0 }}>Perguntas Frequentes</h1>

            <h3
              style={{ color: 'rgba(255, 255, 255, 0.8)', fontWeight: 400, lineHeight: '24px', paddingBottom: '60px' }}
            >
              O que os futuros clientes estão perguntando
            </h3>
          </span>
          <DoubleContainer>
            <img width={isMedium ? '100%' : '461px'} src={faq} alt="ROG3R" />
            <span style={{ textAlign: 'start', width: isMedium ? '100%' : '500px' }}>
              <h2 style={{ margin: 0 }}>Como funciona ?</h2>
              <p
                style={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  fontWeight: 400,
                  lineHeight: '24px',
                  paddingBottom: '15px',
                }}
              >
                Após o preenchimento do formulário de compromisso de desenvolvimento, a nossa equipe irá entrar em
                contato via WhatsApp para iniciar o processo de desenvolvimento do seu Aplicativo.
              </p>

              <h2 style={{ margin: 0 }}>Preciso fazer modificações no meu sistema atual ?</h2>
              <p
                style={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  fontWeight: 400,
                  lineHeight: '24px',
                  paddingBottom: '15px',
                }}
              >
                Não. Nós fazemos uma integração via API diretamente com a empresa que fornece o seu sistema de
                gerenciamento. Você irá acessar o painel do seu sistema e gerar um token de integração, após isso o seu
                aplicativo estará integrado com o sistema.
              </p>

              <h2 style={{ margin: 0 }}>Meu Aplicativo vai estar disponivel na GooglePlay e AppStore ?</h2>
              <p
                style={{
                  color: 'rgba(255, 255, 255, 0.8)',
                  fontWeight: 400,
                  lineHeight: '24px',
                  paddingBottom: '15px',
                }}
              >
                Sim, nos responsabilizamos em fazer a publicação do seu aplicativo nas plataformas sem taxas extras ou
                custos adicionais.
              </p>
            </span>
          </DoubleContainer>
        </TopicContainer>
      </Section>

      <Section ref={priceSection} dark>
        <TopicContainer>
          <DoubleContainer>
            <img width={isMedium ? '100%' : '409px'} src={devices2} alt="ROG3R" />
            <span style={{ textAlign: 'start' }}>
              <h1 style={{ margin: 0 }}>PREÇO</h1>
              <p style={{ color: 'rgba(255, 255, 255, 0.8)', fontWeight: 400, lineHeight: '24px' }}>
                Essa é a sua chance de ter o seu próprio aplicativo desenvolvido pela melhor plataforma de aplicativos
                para provedores do Brasil.
              </p>
              {!isValid ? (
                <h1 style={{ marginBottom: 0, paddingTop: '20px', fontSize: '45px' }}>R$250,00</h1>
              ) : (
                <>
                  <h1 style={{ paddingTop: '20px', fontSize: '45px', marginBottom: '5px' }}>R$150,00</h1>
                  <p
                    style={{
                      fontSize: '24px',
                      color: 'rgba(255, 255, 255, 0.8)',
                      fontWeight: 400,
                      lineHeight: '24px',
                      textDecoration: 'line-through',
                    }}
                  >
                    R$250,00
                  </p>
                </>
              )}
              <p style={{ color: 'rgba(255, 255, 255, 0.8)', fontWeight: 400, lineHeight: '24px' }}>
                * MENSALIDADE FIXA
                <br />* SEM TAXAS ADICIONAIS
                <br />* SEM REAJUSTES
              </p>

              <span
                style={{
                  display: 'grid',
                  gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr 1fr' : 'max-content max-content',
                  gap: '10px',
                }}
              >
                <Button color="gradient">OBTER MEU APP</Button>
                {!isValid && (
                  <Button color="secondary" onClick={() => setCodeModal(true)}>
                    APLICAR CUPOM
                  </Button>
                )}
              </span>
            </span>
          </DoubleContainer>
        </TopicContainer>
      </Section>

      <Footer>
        <img width="96px" src={rog3rFooter} alt="ROG3R" />
        ROG3R SOFTWARE & SERVIÇOS
        <br />
        CNPJ: 46.396.114/0001-01
        <span style={{ display: 'block', paddingTop: '20px', justifyContent: 'center', alignItems: 'center' }}>
          © {new Date().getFullYear()} - Powered By ROG3R
        </span>
      </Footer>
      <Modal visible={modal} onClose={() => setModal(false)}>
        <img width="296px" src={devices2} alt="ROG3R" />
      </Modal>
      <Modal visible={codeModal} onClose={() => setCodeModal(false)}>
        <Subtitle style={{ color: 'black' }}>CÓDIGO PROMOCIONAL</Subtitle>
        <Input
          value={promotionCode}
          type="text"
          onChange={(evt) => setPromotionCode(evt.target.value)}
          style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '32px', border: '2px solid #969696' }}
        />
      </Modal>
    </Container>
  );
};
