import React from 'react';
import styled from 'styled-components';

const StyledNav = styled.div`
  display: grid;
  grid-template:
    'Top' 150px
    'Content' max-content
    / 100%;
`;

type Props = {
  children: React.ReactNode;
};
export const Nav: React.FC<Props> = ({ children }) => <StyledNav>{children}</StyledNav>;
