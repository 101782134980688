import React from 'react';
import styled from 'styled-components';
import logo from '../../resources/logo.png';

const StyledNavBar = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  grid-area: Top;
`;

export const NavBar: React.FC = () => (
  <StyledNavBar>
    <img width="181px" src={logo} alt="ROG3R" />
  </StyledNavBar>
);
