import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Nav } from './components/Nav';
import { EmailConfirmationPage } from './pages/EmailConfirmation';
import { HomePage } from './pages/Home';
import { RecoveryPasswordPage } from './pages/RecoveryPassword';
import { RequestPasswordPage } from './pages/ResquestPassword';

export const routes = {
  index: '/',
  requestPass: '/requestpass',
  confirmation: '/confirmation/:email',
  recoveryPass: '/recovery/:token',
};

export const App = () => {
  return (
    <BrowserRouter>
      <Nav>
        <Routes>
          <Route path={routes.index} element={<HomePage />} />
          <Route path={routes.requestPass} element={<RequestPasswordPage />} />
          <Route path={routes.confirmation} element={<EmailConfirmationPage />} />
          <Route path={routes.recoveryPass} element={<RecoveryPasswordPage />} />
        </Routes>
      </Nav>
    </BrowserRouter>
  );
};
