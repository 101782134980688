import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledModal = styled.div<{ show: boolean }>`
  position: fixed;
  display: flex;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: visibility 0.25s, opacity 0.25s linear;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.8;
`;

const Box = styled.div`
  display: grid;
  position: relative;

  z-index: 2;
  background-color: #ffffff;
  border-radius: 15px;
  padding-top: 55px;
  padding-bottom: 55px;

  justify-content: center;
  align-items: center;
  justify-items: center;

  @media (min-width: 400px) {
    width: 375px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 3;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-radius: 100px;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export type Props = {
  children?: React.ReactNode;
  visible?: boolean;
  onClose?: () => void;
};
export const Modal: React.FC<Props> = ({ children, visible = false, onClose }) => {
  const [visibility, setVisibility] = useState(visible);

  useEffect(() => {
    if (!visible) onClose?.call(this);
    setVisibility(visible);
  }, [visible]);

  useEffect(() => {
    if (!visibility) onClose?.call(this);
  }, [visibility]);

  return (
    <StyledModal show={visible}>
      <Overlay onMouseDown={() => setVisibility(false)} />
      <Box>
        <CloseButton onClick={() => setVisibility(false)}>X</CloseButton>
        {children}
      </Box>
    </StyledModal>
  );
};
