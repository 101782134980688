import React from 'react';

import { BarCode } from './BarCode';
import { Bell } from './Bell';
import { Company } from './Company';
import { Key } from './Key';
import { Location } from './Location';
import { Movie } from './Movie';
import { Network } from './Network';
import { Speed } from './Speed';
import { SpeedTest } from './SpeedTest';
import { WhatsApp } from './WhatsApp';
import { DownDetector } from './DownDetector';
import { Bars } from './Bars';
import { Check } from './Check';
import { Fast } from './Fast';
import { Wireless } from './Wireless';
import { Telegram } from './Telegram';
import { Open } from './Open';
import { TV } from './TV';
import { User } from './User';
import { Pix } from './Pix';
import { Info } from './Info';
import { ArrowRight } from './ArrowRight';
import { Download } from './Download';
import { Support } from './Support';
import { CashHand } from './CashHand';
import { Cash } from './Cash';
import { Exit } from './Exit';
import { Schedule } from './Schedule';
import { Cross } from './Cross';
import { CreditCard } from './CreditCard';
import { Ethernet } from './Ethernet';
import { Dollar } from './Dollar';
import { Phone } from './Phone';
import { Instagram } from './Instagram';
import { HelpDesk } from './HelpDesk';
import { Facebook } from './Facebook';
import { Hamburguer } from './Hamburguer';

import styled from 'styled-components';

const Container = styled.div<{ area: string; pointer: boolean }>`
  display: flex;

  justify-content: center;
  justify-items: center;
  align-items: center;
  grid-area: ${(props) => props.area};
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;

export const colors = {
  black: '#000000',
  light: '#ffffff',
};
type Color = keyof typeof colors;

export const sizes = {
  small: { width: 20, height: 20 },
  medium: { width: 22, height: 22 },
  large: { width: 30, height: 30 },
  xlarge: { width: 40, height: 40 },
  xxlarge: { width: 80, height: 80 },
  square: { width: 30, height: 30 },
};
type Size = keyof typeof sizes;

export type VectorProps = {
  color?: Color;
  size?: Size;
};

const icons = {
  BarCode,
  Bell,
  Company,
  Key,
  Location,
  Movie,
  Network,
  Speed,
  WhatsApp,
  SpeedTest,
  DownDetector,
  Bars,
  Check,
  Wireless,
  Fast,
  Telegram,
  Open,
  TV,
  User,
  Pix,
  Info,
  ArrowRight,
  Download,
  Cash,
  CashHand,
  Support,
  Exit,
  Schedule,
  Cross,
  CreditCard,
  Ethernet,
  Dollar,
  Phone,
  Instagram,
  HelpDesk,
  Facebook,
  Hamburguer,
};
export type Names = keyof typeof icons;

export type IconProps = {
  name: Names;
  size?: VectorProps['size'];
  color?: VectorProps['color'];
  area?: string;
  onClick?: () => void;
};
export const Icon: React.FC<IconProps> = ({ name, size, color, area, onClick }) => {
  if (onClick) {
    return (
      <Container area={area ?? 'unset'} onClick={onClick} pointer={onClick !== undefined}>
        {React.createElement(icons[name], { color, size })}
      </Container>
    );
  }
  return <>{React.createElement(icons[name], { color, size })}</>;
};
